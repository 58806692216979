.startMenu {

  .speciesSelector {
    .body {
      .species-wrapper {
        display: flex;
        padding-bottom: 15px;
        border-bottom: 1px solid $core_border;
        margin-bottom: 5px;
      }

      .entry-points-wrapper {
        margin-top: 15px;
      }

      .species-group {
        position: relative;

        &.active:before {
          position: absolute;
          width: 0;
          height: 0;
          bottom: -22%;
          left: 25%;
          content: "";
          border-style: solid;
          border-width: 0 13px 13.7px 13px;
          border-color: transparent transparent $header-menu-bg-color transparent;
          pointer-events: none;
        }

        &.active:after {
          position: absolute;
          width: 0;
          height: 0;
          bottom: -23%;
          left: 26%;
          content: "";
          border-style: solid;
          border-width: 0 12px 12.7px 12px;
          border-color: transparent transparent #fff transparent;
          pointer-events: none;
        }
      }

      label[for^="speciesSelector"] {
        cursor: pointer;
        margin-right: 1em;
        padding: 0.6em .25em .6em;
        border: solid .2em $core_border;
        border-radius: .5em;
        position: relative;
        color: $core_colour_selected;

        transition: background-color, color ease 0.2s;

        &:after {
          display: block;
          visibility: hidden;
          position: absolute;
          bottom: -66%;
          left: 4%;
          opacity: 0;
          content: attr(data-title);
          text-transform: capitalize;
          height: auto;
          padding: 5px 8px;
          z-index: 999;
          color: #fff;
          text-decoration: none;
          text-align: center;
          background: rgba(0, 0, 0, 1);
          border-radius: 3px;
          pointer-events: none;
          transition: all 0.2s ease-in-out;
          white-space: nowrap;
        }

        &:before {
          position: absolute;
          visibility: hidden;
          width: 0;
          height: 0;
          bottom: -16%;
          left: 42%;
          opacity: 0;
          content: "";
          border-style: solid;
          border-width: 0 5px 8.7px 5px;
          border-color: transparent transparent rgba(0, 0, 0, 1) transparent;
          pointer-events: none;
          transition: all 0.2s ease-in-out;
        }

        &:active:after,
        &:active:before,
        &:focus:after,
        &:focus:before,
        &:hover:after,
        &:hover:before {
          visibility: visible;
          opacity: 1;
        }
      }

      label:active,
      label:focus,
      label:hover,
      input[type=radio]:checked + label {
        color: $core_colour_text;
        border-color: $core_colour_text;
      }

      input[type=radio] {
        display: none;
      }

      .startMenuSearch {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        form {
          width: 80%;
        }
      }
    }
  }

  .speciesSelector > header > h1 > label:before {
    @extend .fas;
    content: fa-content($fa-var-database);
    width: 1.8em;
    text-align: center;
  }
}

;@import "sass-embedded-legacy-load-done:106";