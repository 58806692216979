
div.touring {
  flex: 1 0 auto; // sidebar uses flexbox
  // the touring div itself also uses a flexbox for the children:
  display: flex;
  flex-direction: column;
  padding-left: 0.5em;

  .touring-intro-text {
    margin-top: 5px;
    margin-bottom: 15px;
    padding: 0 0.5em;
  }

  .input {
    margin-right: 0.5em;

    // task switcher & task input
    overflow: hidden; // from the bootstrap stuff we get some overflow which introduces scrollbars --> hide it
  
    .type {
      .row {
        .btn-group {
          label {
            height: 60%;
          }
        }
      }
    }
  }
  
  .removeMiniVis-btn {
    float: right !important;
    padding: 0px 7px !important;
    margin: 2px 1px !important;
  }

  label.col-form-label {
    padding: 0px;
    padding-left: 15px; // bootstrap default
  }

  .output {
    flex: 1;
    position: relative;
    $wide-col-padding: 0.5em !default;
    $narrow-col-padding: 0.2em !default;
    $col-min-size: 4.5ch !default;
    $col-max-size: 15em !default;

    /*.select2-dropdown { // dropdown is seperatly attached to DOM
      $hover-background-color: shade-color($color: #ddd, $amount: 20%); // #ddd is default color for selected elements
      $hover-color: white !default;
      .select2-results>.select2-results__options {
        max-height: 25vh; // 50% of browser window
      }
      .select2-results__option { // changes the padding of every option in the dropdown
        padding: 2px 6px;
      }
      .select2-results__option--highlighted { // hover effect for non nested options (not in optgroup)
        background-color: $hover-background-color; // do my own hover styling
        color: $hover-color;
      }
      .select2-results__options--nested { // hover effect for options in optgroups
        .select2-results__option--highlighted { // inside the dropdown, which is not inside the .select2
          background-color: transparent; // default hover styling is done by adding a class
          color: $dark-font-color;
          &[aria-selected=true] { // reset
            background-color: #ddd;
            color: $dark-font-color;
          }
        }
        .select2-results__option:hover {
          background-color: $hover-background-color; // do my own hover styling
          color: $hover-color;
        }
      }
      .select2-results__group {
        cursor: pointer; // let it look clickable
        &:hover {
          background-color: $hover-background-color;
          color: $hover-color;
        }
      }
    }
    .select2 { // the input that is always visible
      .select2-selection__choice {
        background-color: $active-color;
        span {
          color: $dark-font-color;
        }
      }
    }*/

    .task {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;


      .table-container {
        position: relative;
        min-height: 100px;
        border-top: 1px #aaa solid;

        header {
          z-index: -1; // above td elements
          background-color: white;

          h1 {
            font-size: 1.2em;
          }

          p {
            font-weight: normal;
          }
        }
      }

      .details svg .axis path,
      .details svg .axis line {
        fill: none;
        stroke-width: 1;
        stroke:  $core_color_almost_black;
        shape-rendering: crispEdges;
      }
    }

    .measure-legend {
      margin-top: 1em;
      margin-bottom: 0.5em;

      svg {
        vertical-align: middle;
      }
    }

    table {
      $table-border-color: white !default;

      table-layout: fixed; // The table and column widths are set by the widths of table and col or by the width of the first row of cells. Cells in other rows do not affect column widths.

      tbody.bottom-margin {
        border-bottom: 0.5em solid transparent; // table's border spacing applies to all rows and tbody has no margin
      }

      th {
        min-width: $col-min-size;
        max-width: $col-min-size;
        position: sticky;
        top: 0;
        z-index: 2;

        &:not(.head) {
          // cells of row labels in header
          min-width: $col-max-size * 0.7; // flexible from min to max
          max-width: $col-max-size;
          padding: 0 $wide-col-padding;
          background-color: white;
        }

        &.head {
          &.rotate {
            // adapted from https://css-tricks.com/rotated-table-column-headers/
            height: 120px;

            svg {
              position: absolute;
              top: 0;
              left: 0;

              polygon {
                fill: white;
              }
            }

            > div {
              transform: translate(12px, 48px) // 3. Back to the correct position
                rotate(-45deg) // 2. Rotate to correct angle
                skew(
                  45deg,
                  0deg
                ); // 1. skew the div, so that the background is correct (text content is unskewed below)
              width: 30px;

              > span {
                position: relative;
                display: inline-block; // to set a width
                min-width: 165px;
                max-width: 165px;
                box-sizing: border-box; // i dont want to mess with the padding

                padding-bottom: 0px;
                padding-top: 1px;
                line-height: 1.25; // defines height of headers
                border-bottom: 2px solid $table-border-color; // make space between headers non-transparent (removed for last header below)
                background-color: white;

                > span {
                  transform: skew(-45deg, 0deg); // unskew the text

                  max-width: 100%; // just as wide as the parent
                  display: inline-block; // to make width & string truncation work
                  vertical-align: bottom; // at the bottom of the parent
                  overflow: hidden; // important to stop text at right border
                  white-space: nowrap; // no line breaks
                  text-overflow: ellipsis;
                  font-weight: normal;

                  box-sizing: border-box; // i dont want to mess with the padding
                  padding: 0.1em 0.5em 0.1em 1.5em;
                }
              }
            }

            &:last-child > div > span {
              border-bottom: none; // no need for a border on last header
            }
          }
        }
      }

      .cross-selection,
      .cross-selection::before,
      .cross-selection span::before {
        background-color: $highlight-color !important; // important because to overwrite the htmml inline color definition
        color: black !important;
      }

      td {
        border-collapse: collapse;
        position: relative;
        background: white;
        background-clip: padding-box; // fix firefox bug: td background stretches over tbody border

        &[rowspan] {
          vertical-align: text-top; // move text of multi-row cells to the top
        }

        &:not(.score) {
          // row labels
          min-width: $col-max-size * 0.7;
          max-width: $col-max-size;
          padding: 0 $wide-col-padding;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.score {
          // no min-width here because than the cell gets larger on hover (wich makes the text bolder)
          max-width: $col-min-size;
          text-align: center; // padding workaround, because we make the font bolder on hover and can use the whitespace of the cell this way
          overflow: hidden;
          text-overflow: clip; // cells are so small, the ... would take to much space
          white-space: nowrap;

          // border-style: solid;
          // border-color: $table-border-color;
          // border-width: 0px; // overwrite the default
          // border-right-width: 1px; // just on the right
        }

        &.action,
        &.score {
          cursor: pointer;
          vertical-align: bottom; // Actually, it should be middle, but bottom looks more centered ¯\_(ツ)_/¯

          &:hover,
          &:focus {
            // = td.action:hover
            background-color: shade-color(
              $highlight-color,
              25%
            ) !important; // important because to overwrite the htmml inline color definition
            color: black !important;
            font-weight: bolder;
          }

          &.selectedCell {
            background-color: #fba74d !important; // important because to overwrite the htmml inline color definition
            color: black !important;
            font-weight: bolder;
          }
        }

        span.circle {
          display: inline-block;
          width: 0.8em;
          height: 0.8em;
          vertical-align: middle;
          background-color: $dark-font-color;
          border: 1px solid transparent;
          border-radius: 100%;
        }
      }
    }

    .detailVis {
      border-top: #aaa 1px solid;
      padding: 0.5em 0;

      .detailDiv {
        font-weight: bold;
        color: $dark-font-color;

        span {
          font-weight: normal;

          &.detail-label {
            font-weight: bold;
          }
        }
      }
    }

    // ------ parallel sets with D3
    .dimension tspan.name {
      font-size: 1.2em;
      fill: $dark-font-color;
      font-weight: bold;
    }

    .dimension tspan.sort {
      fill: #000;
      cursor: pointer;
      opacity: 0;
    }

    .dimension tspan.sort:hover {
      fill: $dark-font-color;
    }

    .dimension:hover tspan.name {
      fill: #000;
    }

    .dimension:hover tspan.sort {
      opacity: 1;
    }

    .dimension line {
      stroke: #000;
    }

    .dimension rect {
      stroke: none;
      fill-opacity: 0;
    }

    .dimension > rect,
    .category-background {
      fill: #fff;
    }

    .dimension > rect {
      display: none;
    }

    // .category:hover rect { fill-opacity: .3; }
    // .dimension:hover > rect { fill-opacity: .3; }
    svg .parSets .ribbon path {
      stroke-opacity: 0;
      fill-opacity: 0.5;
    }

    // .ribbon path.active { fill-opacity: .9; }
    .ribbon-mouse path {
      fill-opacity: 0;
      stroke: none;
    }

    .ribbon path.selected {
      fill-opacity: 0.9 !important;
    }

    // new
    .category rect.selected {
      fill-opacity: 0.3;
    }

    // new
    .dimension:hover > rect.selcted {
      fill-opacity: 0.3;
    }

    // new

    // predefined colours
    .category-0 {
      fill: #1f77b4;
      stroke: #1f77b4;
    }

    .category-1 {
      fill: #ff7f0e;
      stroke: #ff7f0e;
    }

    .category-2 {
      fill: #2ca02c;
      stroke: #2ca02c;
    }

    .category-3 {
      fill: #d62728;
      stroke: #d62728;
    }

    .category-4 {
      fill: #9467bd;
      stroke: #9467bd;
    }

    .category-5 {
      fill: #8c564b;
      stroke: #8c564b;
    }

    .category-6 {
      fill: #e377c2;
      stroke: #e377c2;
    }

    .category-7 {
      fill: #7f7f7f;
      stroke: #7f7f7f;
    }

    .category-8 {
      fill: #bcbd22;
      stroke: #bcbd22;
    }

    .category-9 {
      fill: #17becf;
      stroke: #17becf;
    }

    .category-10 {
      fill: #aec7e8;
      stroke: #aec7e8;
    }

    .category-11 {
      fill: #ffbb78;
      stroke: #ffbb78;
    }

    .category-12 {
      fill: #98df8a;
      stroke: #98df8a;
    }

    .category-13 {
      fill: #ff9896;
      stroke: #ff9896;
    }

    .category-14 {
      fill: #c5b0d5;
      stroke: #c5b0d5;
    }

    .category-15 {
      fill: #c49c94;
      stroke: #c49c94;
    }

    .category-16 {
      fill: #f7b6d2;
      stroke: #f7b6d2;
    }

    .category-17 {
      fill: #c7c7c7;
      stroke: #c7c7c7;
    }

    .category-18 {
      fill: #dbdb8d;
      stroke: #dbdb8d;
    }

    .category-19 {
      fill: #9edae5;
      stroke: #9edae5;
    }

    .category-gray {
      fill: #808080 !important;
      stroke: #808080 !important;
    }

    .category-selected {
      fill: #fba74d !important;
      stroke: #fba74d !important;
    }

    // ------ boxplot with D3
    g.box-element text {
      stroke: none;
      fill: black;
    }

    g.box-element {
      rect.box {
        stroke: black;
        stroke-width: 1;
        stroke-opacity: 0;
        fill-opacity: 0.5;
      }

      line.center,
      line.median,
      line.whisker {
        stroke-opacity: 0.2;
      }
    }

    g.box-element.selected {
      rect.box {
        stroke: black;
        stroke-width: 1;
        stroke-opacity: 1;
        fill-opacity: 1;
      }

      line.center,
      line.median,
      line.whisker {
        stroke-opacity: 1;
      }
    }

    // ------ scattter plot with D3
    g.scatterplot circle.datapoint {
      stroke: none;
      opacity: 1;
      fill: black;
    }

    g.scatterplot circle.datapoint:hover {
      opacity: 1;
      fill: #fba74d !important;
    }

    g.scatterplot g.regression {
      path,
      line {
        stroke: rgb(194, 194, 194) !important;
        stroke-width: 2 !important;
      }
    }

    // // ------ line chart with D3
    g.linechart path.dataline {
      fill-opacity: 0;
      fill: #fba74d;
      stroke-width: 2;
      stroke-linejoin: "round";
      stroke-linecap: "round";
    }

    g.linechart g.baseline {
      fill: "none";
      stroke-width: 1;
      stroke-linejoin: "round";
      stroke-linecap: "round";
      stroke: black;
    }

    g.linechart path.dataline:hover {
      fill-opacity: 0.4;
    }
  }

  @at-root {
    @font-face {
      font-family: lu-font;
      src: url('~lineupjs/src/assets/font/lu-font.eot');
      src: url('~lineupjs/src/assets/font/lu-font.eot') format('embedded-opentype'),
        url('~lineupjs/src/assets/font/lu-font.woff2') format('woff2'), url('~lineupjs/src/assets/font/lu-font.woff') format('woff'),
        url('~lineupjs/src/assets/font/lu-font.ttf') format('truetype'), url('~lineupjs/src/assets/font/lu-font.svg') format('svg');
      font-weight: normal;
      font-style: normal;
    }
  }

  [data-type]::before {
    display: inline-block; // so that the width property works
    width: 1.2em; // to have some space between icon and the text in the td
    color: #999; // #515151 is to dark

    font: normal normal normal 14px/1 lu-font, serif; // shortening font declaration
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  [data-type="categorical"]::before {
    content: "\e810";
  }

  [data-type="number"]::before {
    content: "\e80f";
  }
}

;@import "sass-embedded-legacy-load-done:99";