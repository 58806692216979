.ordino-session-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: $session-list-max-height;
}

.session-item:not(:last-child) {
  border-bottom: 1px solid $gray-300;
}

;@import "sass-embedded-legacy-load-done:242";