.ordino-footer {
  background-color: $gray-800;

  .ordino-footer-navigation {
    gap: 1rem;

    .list-group-item {
      border: none;
      background-color: transparent;
    }

    a {
      color: $white;

      &:hover {
        color: $ordino-white-hover;
      }
    }
  }

  .ordino-footer-logo a {
    text-decoration: none;
  }
}

;@import "sass-embedded-legacy-load-done:240";