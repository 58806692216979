.expressionVsCopyNumber, .coExpression {

  .title {
      font-weight: 500;
  }

  .axis path,
  .axis line {
    fill: none;
    stroke-width: 1;
    stroke: $core_color_almost_black;
    shape-rendering: crispEdges;
  }

  .mark {
    fill: $core_color_almost_black;
    fill-opacity: 0.8;
    cursor: pointer;

    &.clicked {
      stroke: $select-data-color;
      fill-opacity: 1;
      stroke-width: 2;
    }

    &.disabled {
      fill-opacity: 0.1;
    }

    &:active,
    &:focus,
    &:hover {
      stroke: $hover-data-color;
      fill-opacity: 1;
      stroke-width: 2;
    }
  }

  .tdp-legend {
    padding: 1em;
    display: flex;
    flex: 1 1 100%;
    width: 100%;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    > div {
      flex: 0 0 auto;
      margin: 0 0.5em;
      max-width: 15em;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    &:empty {
      display: none;
    }
  }

  .spearmancoeff {
    text-align: center;
  }

}

;@import "sass-embedded-legacy-load-done:104";