// Modify the Boostrap SASS maps before importing the components
// @see https://getbootstrap.com/docs/5.0/customize/sass/#maps-and-loops

$ordino-white-hover: #FFFFFFBF !default; // add some alpha on hover

$ordino-grid-breakpoints: (
  xxxl: 1800px // additional breakpoint to hide scrollspy nav
) !default;

$grid-breakpoints: map-merge($grid-breakpoints, $ordino-grid-breakpoints);
;@import "sass-embedded-legacy-load-done:32";