.mouse-icon {
    &::before {
      content: "";
      display: block;
      height: 0.85rem;
      background: no-repeat center/20px
        url("~tdp_publicdb/dist/assets/mouse-icon.png");
    }
  }

;@import "sass-embedded-legacy-load-done:108";