.dataset-entry {
  .dataset-entry-item {
    max-height: $named-set-list-max-height;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &:not(:first-child) {
    border-left: 1px solid $gray-300;
  }
}

;@import "sass-embedded-legacy-load-done:243";