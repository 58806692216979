.species-group {
  .mouse-icon:before {
    content: '';
    display: block;
    height: 0.85em;
    background: no-repeat center/50px url('../../assets/previews/mouse-icon.png');
    opacity: 0.1;
    transition: opacity 0.2s ease-in-out;
  }

  & label:hover .mouse-icon:before, &.active .mouse-icon:before {
    opacity: 1;
  }
}

;@import "sass-embedded-legacy-load-done:105";