$oncoprint_cell_height: 25px !default;
$oncoprint_cell_width: 7px !default;
$oncoprint_cell_padding: 2px !default;
$oncoprint_cell_mutation: 8px !default;

$oncoprint_cnv_amplified: '2' !default;
$oncoprint_cnv_amplified_color: #efb3bc !default;
$oncoprint_cnv_amplified_border: transparent !default;

$oncoprint_cnv_deep_deletion: '-2' !default;
$oncoprint_cnv_deep_deletion_color: #92c5de !default;
$oncoprint_cnv_deep_deletion_border: transparent !default;

$oncoprint_cnv_normal: '0' !default;
$oncoprint_cnv_normal_color: #dcdcdc !default;
$oncoprint_cnv_normal_border: transparent !default;

$oncoprint_cnv_unknown: 'NaN' !default;
$oncoprint_cnv_unknown_color: #FCFCFC !default;
$oncoprint_cnv_unknown_border: #dcdcdc !default;

$oncoprint_mut_mutated: 'true' !default;
$oncoprint_mut_mutated_color: #1BA64E !default;
$oncoprint_mut_mutated_border: transparent !default;
$oncoprint_mut_not_mutated: 'false' !default;
$oncoprint_mut_not_mutated_color: #aaa !default;
$oncoprint_mut_not_mutated_border: transparent !default;
$oncoprint_mut_unknown: 'NaN' !default;
$oncoprint_mut_unknown_color: transparent !default;
$oncoprint_mut_unknown_border: #999 !default;

$oncoprint_label_width: 10em !default;

div.parameters .oncoPrintScale {
  display: inline-flex;

  &[data-scale^='sss'] > button:first-of-type {
    cursor: not-allowed;
  }
  &[data-scale^='s'] {
    button:last-of-type {
      cursor: pointer;
    }
  }
  &[data-scale^='s'] > div {
    div:nth-of-type(3) {
      background: none;
    }
    button:last-of-type {
      cursor: not-allowed;
    }
  }
  &[data-scale^='ss'] > div {
    div:nth-of-type(2) {
      background: none;
    }
  }
  &[data-scale^='sss'] > div {
    div:nth-of-type(1) {
      background: none;
    }
  }
  > div {
    display: flex;
    align-items: flex-end;

    > div {
      border: 1px solid #aaa;
      width: 5px;
      margin: 1px;
      background: #dcdcdc;

      &:first-of-type {
        height: 33%;
      }
      &:nth-of-type(2) {
        height: 66%;
      }
      &:last-of-type {
        height: 100%;
      }
    }
  }
  button {
    border: none;
    background: none;
  }
  > button:last-of-type {
    cursor: not-allowed;
  }
}

.oncoPrint {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .legend {
    border-top: 1px solid $core_border;
    width: 100%;
    order: 1; // place at the bottom of gene list

    display: flex;

    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    padding-top: 20px;

    ul {
      margin: 0 40px 0 0;
      padding: 0;
    }

    .title {
      font-weight: 500;
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: 3px;

      &::before {
        display: inline-block;
        content: '';
        border: 1px solid transparent;
        width: 10px;
        height: 25px;
        margin-right: 5px;
      }

      &[data-mut]::before {
        width: 8px;
        height: 8px;
      }
    }

    li[data-cnv='#{$oncoprint_cnv_amplified}']::before {
      background-color: $oncoprint_cnv_amplified_color;
      border-color: $oncoprint_cnv_amplified_border;
    }

    li[data-cnv='#{$oncoprint_cnv_normal}']::before {
      background-color: $oncoprint_cnv_normal_color;
      border-color: $oncoprint_cnv_normal_border;
    }

    li[data-cnv='#{$oncoprint_cnv_deep_deletion}']::before {
      background-color: $oncoprint_cnv_deep_deletion_color;
      border-color: $oncoprint_cnv_deep_deletion_border;
    }

    li[data-cnv='#{$oncoprint_cnv_unknown}']::before {
      background-color: $oncoprint_cnv_unknown_color;
      border-color: $oncoprint_cnv_unknown_border;
    }

    li[data-mut='#{$oncoprint_mut_mutated}']::before {
      background-color: $oncoprint_mut_mutated_color;
      border-color: $oncoprint_mut_mutated_border;
    }

    li[data-mut='#{$oncoprint_mut_not_mutated}']::before {
      background-color: $oncoprint_mut_not_mutated_color;
      border-color: $oncoprint_mut_not_mutated_border;
    }

    li[data-mut='#{$oncoprint_mut_unknown}']::before {
      background-color: $oncoprint_mut_unknown_color;
      border-color: $oncoprint_mut_unknown_border;
    }
  }

  > .alert-info {
    order: 2; // place at the bottom of gene list
  }

  .geneTableWrapper {
    margin-left: $oncoprint_label_width;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px; // space for gene tooltip on hover
  }

  td, th {
    margin: 0;
    white-space: nowrap;
  }

  th.geneLabel {
    cursor: move;
    position: absolute;
    left: 0;
    top: auto;
    color: $core_colour_text;
    width: $oncoprint_label_width;
    text-align: left;

    .ensg {
      display: block;
      font-size: 12px;
      color: #ccc;
    }

    .alterationFreq {
      float: right;
      margin-right: 5px;
      margin-top: 10px;
    }
  }

  .gene {
    margin-top: 10px;
    display: flex;
    align-items: center;
    height: $oncoprint_cell_height + $oncoprint_cell_padding;

    &:first-child {
      margin-top: 0;
    }

    &.ui-sortable-helper th.geneLabel {
      left: -$oncoprint_label_width;
    }

    .cell {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2px;
      width: $oncoprint_cell_width + $oncoprint_cell_padding;
      height: 100%;
      border: 1px solid transparent;

      &.selected {
        border-color: $select-color !important;
      }

      .mut {
        width: 80%;
        border: 1px solid transparent;
        height: $oncoprint_cell_mutation;
      }

      &:after{
        display: block;
        visibility: hidden;
        position: absolute;
        bottom: -35px;
        left: -50%;
        opacity: 0;
        content: attr(data-title);
        height: auto;
        padding: 5px 8px;
        z-index: 999;
        color: #fff;
        text-decoration: none;
        text-align: center;
        background: rgba(0,0,0,1);
        border-radius: 3px;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
        white-space: nowrap;
      }

      &:before {
        position: absolute;
        visibility: hidden;
        width: 0;
        height: 0;
        bottom: -8px;
        left: -10%;
        opacity: 0;
        content: "";
        border-style: solid;
        border-width: 0 5px 8.7px 5px;
        border-color: transparent transparent rgba(0,0,0,1) transparent;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
      }

      &:active:after,
      &:active:before,
      &:focus:after,
      &:focus:before,
      &:hover:after,
      &:hover:before{
        visibility: visible;
        opacity: 1;
      }


      &[data-cnv='#{$oncoprint_cnv_amplified}'] {
        background-color: $oncoprint_cnv_amplified_color;
        border-color: $oncoprint_cnv_amplified_border;
      }

      &[data-cnv='#{$oncoprint_cnv_normal}'] {
        background-color: $oncoprint_cnv_normal_color;
        border-color: $oncoprint_cnv_normal_border;
      }

      &[data-cnv='#{$oncoprint_cnv_deep_deletion}'] {
        background-color: $oncoprint_cnv_deep_deletion_color;
        border-color: $oncoprint_cnv_deep_deletion_border;
      }

      &[data-cnv='#{$oncoprint_cnv_unknown}'] {
        background-color: $oncoprint_cnv_unknown_color;
        border-color: $oncoprint_cnv_unknown_border;
      }

      &[data-mut='#{$oncoprint_mut_mutated}'] div {
        background-color: $oncoprint_mut_mutated_color;
        border-color: $oncoprint_mut_mutated_border;
      }

      &[data-mut='#{$oncoprint_mut_not_mutated}'] div {
        background-color: $oncoprint_mut_not_mutated_color;
        border-color: $oncoprint_mut_not_mutated_border;
      }

      &[data-mut='#{$oncoprint_mut_unknown}'] div {
        background-color: $oncoprint_mut_unknown_color;
        border-color: $oncoprint_mut_unknown_border;
      }
    }

    // last row --> tooltip on top
    &:last-child:not(:first-child) .cell {
      &:after {
        top: -35px;
        bottom: auto;
      }

      &:before {
        bottom: auto;
        top: -8px;
        border-width: 8.7px 5px 0 5px;
        border-color: rgba(0,0,0,1) transparent transparent transparent;
      }
    }

    // only row --> tooltip to right
    &:last-child:first-child .cell {
      &:after {
        bottom: -3px;
        left: 15px;
      }

      &:before {
        z-index: 1;
        bottom: 30%;
        left: 7px;
        border-width: 5px 8.7px 5px 0;
        border-color: transparent rgba(0,0,0,1) transparent transparent;
      }
    }
  }
  &[data-scale^='s'] {
    .cell {
      margin-left: 1px;
      margin-right: 1px;
      width: 6px;

      .mut {
        width: 100%;
      }
    }
  }
  &[data-scale^='ss'] {
    .cell {
      margin-left: 0;
      margin-right: 0;
      width: 5px;
    }
  }

  &[data-scale^='sss'] {
    .cell {
      border: none;
      width: 3px;

      &.selected {
        border: 1px solid $select-color;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:103";