.tdp-legend {
  div {
    cursor: pointer;

    span:first-child {
      display: inline-block;
      width: 10px;
      height: 10px;
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}

;@import "sass-embedded-legacy-load-done:107";