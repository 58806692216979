// keep the footer at the bottom
// @see https://moderncss.dev/keep-the-footer-at-the-bottom-flexbox-vs-grid/
#welcome {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-color: $gray-100;
  color: $gray-800;
}

;@import "sass-embedded-legacy-load-done:250";