.dataset-dropzone {
  border: 1px dashed $gray-600;
  border-radius: 6px;
  padding: 0.6rem;

  p:nth-child(1) {
    font-weight: 500;
    color: $gray-600;
  }
  p:nth-child(2) {
    color: $gray-500;
  }
}

;@import "sass-embedded-legacy-load-done:110";