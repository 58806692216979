.ordino-teaser-cards {
  .col {
    // use flex box to stack headline and card and make cards equally high
    display: flex;
    flex-direction: column;
  }

  .card-footer {
    background-color: transparent;
  }
}

;@import "sass-embedded-legacy-load-done:255";