.ordino-video-card {
    iframe {
      height: 15em;
    }
  
    @media only screen and (min-width: 768px) {
      iframe {
        height: 35em;
      }
    }
  }
  
;@import "sass-embedded-legacy-load-done:247";