
@at-root {
  .select2-results__option,
  .select2-selection__rendered {
    .option-muted {
      font-size: 13px;
      color: #999;
    }

    &.select2-results__option--highlighted {
      .option-muted {
        color: #afcde9;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:101";