.ordino-logo {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
  color: $white;
  display: inline-flex;
  gap: 0.75rem;
  align-items: center;
}

;@import "sass-embedded-legacy-load-done:239";